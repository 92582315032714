import React from 'react';
import BaseContentCard from './BaseContentCard';

type Props = {
  contentCard: Queries.ContentCardFragmentFragment;
  isEven: boolean;
};

const InformationalCard = ({ contentCard, isEven }: Props) => {
  return <BaseContentCard contentCard={contentCard} isEven={isEven} layout="horizontal" />;
};

export default InformationalCard;
