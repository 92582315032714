import React from 'react';
import { Box, Text, Grid, Flex, ThemeUIStyleObject } from 'theme-ui';
import ImgLoader from '~/components/Generic/ImgLoader';
import BadgeHighlight from '../FeaturedCard/BadgeHighlight';
import CTAButtonWithWrapper from '~/components/Generic/CTAButtonWithWrapper';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

type BaseProps = {
  contentCard: Queries.ContentCardFragmentFragment;
  isEven?: boolean;
  layout: 'horizontal' | 'vertical';
};

type TextAlign = 'left' | 'center' | 'right';

const isTextAlign = (value: string | null): value is TextAlign => {
  if (!value) return false;
  return ['left', 'center', 'right'].includes(value);
};

const BaseContentCard: React.FC<BaseProps> = ({ contentCard, isEven = true, layout }) => {
  const imageDesktop = contentCard.images?.[0];
  const imageMobile = contentCard.imagesMobile?.[0];
  const {
    description,
    copyMobile,
    title,
    titleGradient,
    highlights,
    highlightImage,
    titleDesktop,
    titleMobile,
    backgroundColor,
    textColor,
    cta,
    copyAlignment,
    copyAlignmentMobile,
  } = contentCard;

  const isHorizontal = layout === 'horizontal';

  const getTitleColor = () => {
    if (titleGradient) return 'transparent';
    if (textColor) return textColor;
    return 'black';
  };

  const getCopyAlignment = (): TextAlign | TextAlign[] => {
    if (isTextAlign(copyAlignment) && isTextAlign(copyAlignmentMobile)) {
      return [copyAlignmentMobile, copyAlignment];
    }
    if (isTextAlign(copyAlignment)) return copyAlignment;
    if (isTextAlign(copyAlignmentMobile)) return copyAlignmentMobile;

    return ['center', 'left'];
  };

  const titleStyle: ThemeUIStyleObject = {
    m: 0,
    fontWeight: '500',
    fontSize: isHorizontal ? ['2.4rem', '3.2rem'] : ['2.4rem', '2.8rem'],
    textTransform: 'uppercase',
    lineHeight: '100%',
    letterSpacing: '-0.02em',
    textAlign: getCopyAlignment(),
    backgroundImage: titleGradient ? 'linear-gradient(93.95deg, #FE6A00 0%, #244076 47.42%);' : null,
    backgroundClip: titleGradient ? 'text' : null,
    WebkitBackgroundClip: title ? 'text' : null,
    textFillColor: getTitleColor(),
    WebkitTextFillColor: getTitleColor(),
  };

  const copyStyles: ThemeUIStyleObject = {
    '>*': {
      m: 0,
      mt: '1.6rem',
      fontSize: '1.7rem',
      lineHeight: [1.2, 1.4],
      textAlign: getCopyAlignment(),
    },
    ul: {
      pl: '3.2rem',
      textAlign: 'left',
    },
  };

  const gridStyles: ThemeUIStyleObject = isHorizontal
    ? {
        gridTemplateColumns: ['1fr', isEven ? 'minmax(63%, 3fr) 2fr' : '2fr minmax(63%, 3fr)'],
        rowGap: ['2.4rem', 0],
        columnGap: [0, '5.6rem'],
      }
    : {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto 1fr',
        rowGap: '2.4rem',
      };

  const imageStyles: ThemeUIStyleObject = isHorizontal
    ? {
        aspectRatio: ['1/1', '17/10'],
        minHeight: ['0', '300px'],
        maxHeight: [null, '496px'],
        order: [0, isEven ? 0 : 1],
      }
    : {
        aspectRatio: ['69/50', '224/279'],
      };

  const contentStyles: ThemeUIStyleObject = isHorizontal
    ? {
        placeSelf: 'center',
        maxWidth: ['none', '45.6rem'],
        px: ['2.4rem', 0],
        mr: [0, isEven ? '5.6rem' : 0],
        ml: [0, isEven ? 0 : '5.6rem'],
        textAlign: ['center', 'left'],
        flexDirection: 'column',
      }
    : {
        placeSelf: ['center', 'start'],
        px: '2.4rem',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
      };

  return (
    <Grid
      sx={{
        m: 'auto',
        width: '100%',
        height: '100%',
        position: 'relative',
        pb: isHorizontal ? ['2.4rem', 0] : '2.4rem',
        backgroundColor: backgroundColor,
        color: textColor,
        ...gridStyles,
      }}
    >
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          overflow: 'hidden',
          isolation: 'isolate',
          ...imageStyles,
        }}
      >
        {imageDesktop && (
          <ImgLoader
            image={imageDesktop}
            forwardSx={{
              display: ['none', 'block'],
              position: 'absolute',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              top: 0,
              left: 0,
            }}
          />
        )}
        {imageMobile || imageDesktop ? (
          <ImgLoader
            image={imageMobile ?? imageDesktop ?? null}
            forwardSx={{
              display: ['block', 'none'],
              position: 'absolute',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              top: 0,
              left: 0,
            }}
          />
        ) : null}
      </Box>
      <Flex sx={contentStyles}>
        <Box>
          {highlights && highlights?.length > 0 && (
            <Flex sx={{ gap: '1.2rem', mb: ['1.6rem', null, '2.4rem '] }}>
              {highlights.map((highlight, index) => (
                <BadgeHighlight key={`${highlight}-${index}`} text={highlight} />
              ))}
            </Flex>
          )}
          {highlightImage && (
            <Box sx={{ width: '6.4rem', display: ['none', 'block'], mb: ['1.6rem', null, '2.4rem '] }}>
              {/* @ts-ignore */}
              <GatsbyImage image={getImage(highlightImage)} alt={highlightImage.title ?? ''} />
            </Box>
          )}
          {!titleDesktop && !titleMobile && title && (
            <Box sx={{ ...titleStyle }}>
              <Text
                as="h2"
                sx={{
                  'p, h1, h2, h3, h4, h5, h6': {
                    m: 0,
                    ...titleStyle,
                  },
                }}
              >
                {title}
              </Text>
            </Box>
          )}
          {titleDesktop && (
            <Box
              sx={{
                display: ['none', 'block'],
                ...titleStyle,
              }}
            >
              <Text
                as="h2"
                sx={{
                  'p, h1, h2, h3, h4, h5, h6': {
                    m: 0,
                    ...titleStyle,
                  },
                }}
                dangerouslySetInnerHTML={{ __html: titleDesktop?.childMarkdownRemark?.html ?? '' }}
              />
            </Box>
          )}
          {(titleMobile || titleDesktop) && (
            <Box
              sx={{
                display: ['block', 'none'],
                ...titleStyle,
              }}
            >
              <Text
                as="h2"
                sx={{
                  'p, h1, h2, h3, h4, h5, h6': {
                    m: 0,
                    ...titleStyle,
                  },
                }}
                dangerouslySetInnerHTML={{ __html: (titleMobile ?? titleDesktop)?.childMarkdownRemark?.html ?? '' }}
              />
            </Box>
          )}
          {(description || copyMobile) && (
            <>
              {description && (
                <Text
                  sx={{
                    display: copyMobile ? ['none', 'block'] : 'block',
                    ...copyStyles,
                  }}
                  dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark?.html ?? '' }}
                />
              )}
              {copyMobile && (
                <Text
                  sx={{
                    display: ['block', 'none'],
                    ...copyStyles,
                  }}
                  dangerouslySetInnerHTML={{ __html: copyMobile.childMarkdownRemark?.html ?? '' }}
                />
              )}
            </>
          )}
        </Box>
        {!!cta && (
          // @ts-ignore
          <CTAButtonWithWrapper callToAction={cta} forwardSx={{ display: 'block', mt: '2.4rem', mx: ['auto', 0] }} />
        )}
      </Flex>
    </Grid>
  );
};

export default BaseContentCard;
