import { Box, Text, ThemeUIStyleObject } from 'theme-ui';
import LinkRenderer from '~/components/Generic/LinkRenderer';
import useCTAStyle from '~/hooks/components/use-cta-style';

type Props = Pick<
  Queries.ContentCardFragmentFragment,
  'titleDesktop' | 'titleMobile' | 'title' | 'description' | 'titleGradient' | 'cta' | 'copyMobile'
>;
const CardContent = ({ titleDesktop, titleMobile, title, description, titleGradient, cta, copyMobile }: Props) => {
  const titleStyle: ThemeUIStyleObject = {
    m: 0,
    fontWeight: '500',
    fontSize: ['2.4rem', '3.2rem'],
    textTransform: 'uppercase',
    lineHeight: [1, 1.1],
    letterSpacing: '-0.02em',
    backgroundImage: titleGradient ? 'linear-gradient(93.95deg, #FE6A00 0%, #244076 47.42%);' : null,
    backgroundClip: titleGradient ? 'text' : null,
    WebkitBackgroundClip: title ? 'text' : null,
    textFillColor: titleGradient ? 'transparent' : 'black',
    WebkitTextFillColor: titleGradient ? 'transparent' : 'black',
  };
  const CTAStyle = useCTAStyle({
    baseColor: cta?.baseColor ?? '',
    size: cta?.size ?? '',
    variant: cta?.variant ?? '',
    disableDisabledStyle: false,
  });

  return (
    <>
      {!titleDesktop && !titleMobile && title && (
        <Box
          sx={{
            ...titleStyle,
          }}
        >
          <Text
            as="h2"
            sx={{
              'p, h1, h2, h3, h4, h5, h6': {
                m: 0,
                ...titleStyle,
              },
            }}
          >
            {title}
          </Text>
        </Box>
      )}
      {titleDesktop && (
        <Box
          sx={{
            display: ['none', 'block'],
            ...titleStyle,
          }}
        >
          <Text
            as="h2"
            sx={{
              'p, h1, h2, h3, h4, h5, h6': {
                m: 0,
                ...titleStyle,
              },
            }}
            dangerouslySetInnerHTML={{ __html: titleDesktop?.childMarkdownRemark?.html ?? '' }}
          />
        </Box>
      )}
      {titleMobile && (
        <Box
          sx={{
            display: ['block', 'none'],
            ...titleStyle,
          }}
        >
          <Text
            as="h2"
            sx={{
              'p, h1, h2, h3, h4, h5, h6': {
                m: 0,
                ...titleStyle,
              },
            }}
            dangerouslySetInnerHTML={{ __html: titleMobile?.childMarkdownRemark?.html ?? '' }}
          />
        </Box>
      )}
      {description && (
        <Text
          sx={{
            display: ['none', 'block'],
            'p, h1, h2, h3, h4, h5, h6': {
              m: 0,
              fontSize: ['1.7rem', '2.2rem'],
              lineHeight: [1.2, 1.4],
            },
          }}
          dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark?.html ?? '' }}
        />
      )}
      {copyMobile ? (
        <Text
          sx={{
            display: ['block', 'none'],
            'p, h1, h2, h3, h4, h5, h6': {
              m: 0,
              fontSize: ['1.7rem', '2.2rem'],
              lineHeight: [1.2, 1.4],
            },
          }}
          dangerouslySetInnerHTML={{ __html: copyMobile.childMarkdownRemark?.html ?? '' }}
        />
      ) : null}
      {!!cta && (
        // @ts-ignore
        <LinkRenderer
          href={cta?.innerLink}
          sx={{
            ...CTAStyle,
          }}
        >
          {cta?.text}
        </LinkRenderer>
      )}
    </>
  );
};

export default CardContent;
