import React from 'react';
import BaseContentCard from './BaseContentCard';

type Props = {
  contentCard: Queries.ContentCardFragmentFragment;
};

const InformationalVerticalCard = ({ contentCard }: Props) => {
  return <BaseContentCard contentCard={contentCard} layout="vertical" />;
};

export default InformationalVerticalCard;
