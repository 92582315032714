import React from 'react';
import { Box, Text, Grid, ThemeUIStyleObject } from 'theme-ui';
import ImgLoader from '~/components/Generic/ImgLoader';
type Props = {
  contentCard: Queries.ContentCardFragmentFragment;
};
const TabbedCard = ({ contentCard }: Props) => {
  const imageDesktop = contentCard.images?.[0];
  const imageMobile = contentCard.imagesMobile?.[0];
  const { description, title, titleGradient, titleDesktop, titleMobile, backgroundColor } = contentCard;

  const titleStyle: ThemeUIStyleObject = {
    m: 0,
    fontWeight: '450',
    fontSize: ['2.4rem', '3.2rem'],
    textTransform: 'uppercase',
    lineHeight: '100%',
    letterSpacing: '-0.02em',
    backgroundImage: titleGradient ? 'linear-gradient(93.95deg, #FE6A00 0%, #244076 47.42%);' : null,
    backgroundClip: titleGradient ? 'text' : null,
    WebkitBackgroundClip: title ? 'text' : null,
    textFillColor: titleGradient ? 'transparent' : 'black',
    WebkitTextFillColor: titleGradient ? 'transparent' : 'black',
  };

  return (
    <Grid
      sx={{
        backgroundColor: backgroundColor,
        gridTemplateColumns: ['1fr', '3fr 2fr'],
        px: ['2.4rem', 0],
        pb: ['2.4rem', '0'],
      }}
    >
      <Box
        sx={{
          alignSelf: 'start',
          justifySelf: 'center',
          width: ['100%', 'auto'],
          px: [0, '5.6rem'],
          pt: ['3.2rem', '5.6rem'],
          pb: ['2.4rem', '5.6rem'],
          textAlign: ['center', 'left'],
        }}
      >
        {titleDesktop && (
          <Box
            sx={{
              display: [titleMobile ? 'none' : 'block', 'block'],
              ...titleStyle,
            }}
          >
            <Text
              as="h2"
              sx={{
                'p, h1, h2, h3, h4, h5, h6': {
                  m: 0,
                  ...titleStyle,
                },
              }}
              dangerouslySetInnerHTML={{ __html: titleDesktop?.childMarkdownRemark?.html ?? '' }}
            />
          </Box>
        )}
        {titleMobile && (
          <Box
            sx={{
              display: ['block', 'none'],
              ...titleStyle,
            }}
          >
            <Text
              as="h2"
              sx={{
                'p, h1, h2, h3, h4, h5, h6': {
                  m: 0,
                  ...titleStyle,
                },
              }}
              dangerouslySetInnerHTML={{ __html: titleMobile?.childMarkdownRemark?.html ?? '' }}
            />
          </Box>
        )}
        {description && (
          <Text
            sx={{
              'p, li, h1, h2, h3, h4, h5, h6': {
                m: 0,
                mt: ['1.6rem', '2.4rem'],
                fontSize: '1.7rem',
                lineHeight: [1.2, 1.4],
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              },
              li: {
                m: 0,
                textAlign: 'left',
              },
            }}
            dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark?.html ?? '' }}
          />
        )}
      </Box>
      <Box
        sx={{
          aspectRatio: ['1/1', '95/86'],
          width: '100%',
          minHeight: ['0', '300px'],
          maxHeight: [null, '496px'],
          position: 'relative',
          overflow: 'hidden',
          isolation: 'isolate',
          px: ['2.4rem', '0'],
        }}
      >
        {imageDesktop && (
          <ImgLoader
            image={imageDesktop}
            forwardSx={{
              display: ['none', 'block'],
              position: 'absolute',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              top: 0,
              left: 0,
            }}
          />
        )}
        {imageMobile || imageDesktop ? (
          <ImgLoader
            image={imageMobile ?? imageDesktop ?? null}
            forwardSx={{
              display: ['block', 'none'],
              position: 'absolute',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              top: 0,
              left: 0,
            }}
          />
        ) : null}
      </Box>
    </Grid>
  );
};

export default TabbedCard;
