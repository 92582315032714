import { Flex, Grid } from 'theme-ui';
import CardContent from '../CardContent';
import ImgLoader from '~/components/Generic/ImgLoader';

type Props = {
  contentCard: Queries.ContentCardFragmentFragment;
};

const P2Featurette = ({ contentCard }: Props) => {
  const {
    backgroundColor,
    images,
    imagesMobile,
    description,
    titleMobile,
    titleDesktop,
    cta,
    title,
    titleGradient,
    copyMobile,
  } = contentCard;
  const imageDesktop = images?.[0];
  const imageMobile = imagesMobile?.[0];
  return (
    <Flex
      sx={{
        minHeight: ['34.5rem', '35rem'],
        backgroundColor: backgroundColor ?? 'white',
        columnGap: ['1.6rem', '0.8rem'],
        gap: ['1.6rem', '2.4rem', '4.8rem'],
      }}
    >
      <Grid
        sx={{
          flex: ['0 0 50%', '1 1 62%'],
          gridTemplateColumns: ['1fr', `repeat(${images?.length}, minmax(min-content, 1fr))`],
          gap: '0.8rem',
        }}
      >
        {images && images.length > 0
          ? images.map((image) => (
              <ImgLoader
                key={image?.id}
                objectFit="cover"
                image={image}
                forwardSx={{
                  display: ['none', 'block'],
                  width: '100%',
                  height: '100%',
                }}
              />
            ))
          : null}
        {imageMobile || imageDesktop ? (
          <ImgLoader
            objectFit="cover"
            image={imageMobile ?? imageDesktop}
            forwardSx={{
              display: ['block', 'none'],
              width: '100%',
              height: '100%',
            }}
          />
        ) : null}
      </Grid>
      <Grid
        sx={{
          pr: ['2.4rem', '8rem'],
          py: '3.2rem',
          placeSelf: 'center',
          flex: ['0 0 50%', '1 1 38%'],
        }}
      >
        <CardContent
          titleMobile={titleMobile}
          titleDesktop={titleDesktop}
          description={description}
          cta={cta}
          title={title}
          titleGradient={titleGradient}
          copyMobile={copyMobile}
        />
      </Grid>
    </Flex>
  );
};

export default P2Featurette;
